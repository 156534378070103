const PAYMENT_TYPE = {
  lingti_token: '购买点数',
  entry: '报名费',
  vip: '购买会员',
  item: '商城',
}

const SUIT_ORDER = {
  S: 1, // 黑桃
  C: 2, // 梅花
  D: 3, // 方块
  H: 4, // 红心
  J: 5, // 王
}

const DIRECTION_RANK = Array(4)

const RANK_ORDER = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A', 'L', 'B']

const RANK_ORDER_OPTIONS = [
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: 'J', value: 11 },
  { label: 'Q', value: 12 },
  { label: 'K', value: 13 },
  { label: 'A', value: 14 },
]

const LEVEL_ORDER = ['', '', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A', 'A+']

const SUITS = {
  S: '♠', // Spades
  C: '♣', // Clubs
  H: '♥', // Hearts
  D: '♦', // Diamonds
  J: 'JOKER', // Joker
}

const SUIT = {
  S: '♠', // Spades
  C: '♣', // Clubs
  H: '♥', // Hearts
  D: '♦', // Diamonds
  J: '★', // Joker
}

const POINTS = {
  A: 'A',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  J: 'J',
  Q: 'Q',
  K: 'K',
  L: 'JOKER',
  B: 'JOKER',
}

const SUIT_COLORS = {
  S: 'text-black',
  H: 'text-red-600',
  D: 'text-red-600',
  C: 'text-black',
  J: 'text-yellow-600',
}

const SUIT_COLOR = {
  '♠': 'text-black',
  '♥': 'text-red-500',
  '♦': 'text-red-500',
  '♣': 'text-black',
  '★': 'text-yellow-600',
}

const CARD_EXAMPLE = [
  'AS',
  '2S',
  '3S',
  '4S',
  '5S',
  '6S',
  '7S',
  '8S',
  '9S',
  '10S',
  'JS',
  'QS',
  'KS',
  'AS',
  '2S',
  '3S',
  '4S',
  '5S',
  '6S',
  '7S',
  '8S',
  '9S',
  '10S',
  'JS',
  'QS',
  'KS',
  'LJ',
  'AC',
  '2C',
  '3C',
  '4C',
  '5C',
  '6C',
  '7C',
  '8C',
  '9C',
  '10C',
  'JC',
  'QC',
  'KC',
  'AC',
  '2C',
  '3C',
  '4C',
  '5C',
  '6C',
  '7C',
  '8C',
  '9C',
  '10C',
  'JC',
  'QC',
  'KC',
  'LJ',
  'AH',
  '2H',
  '3H',
  '4H',
  '5H',
  '6H',
  '7H',
  '8H',
  '9H',
  '10H',
  'JH',
  'QH',
  'KH',
  'AH',
  '2H',
  '3H',
  '4H',
  '5H',
  '6H',
  '7H',
  '8H',
  '9H',
  '10H',
  'JH',
  'QH',
  'KH',
  'BJ',
  'AD',
  '2D',
  '3D',
  '4D',
  '5D',
  '6D',
  '7D',
  '8D',
  '9D',
  '10D',
  'JD',
  'QD',
  'KD',
  'AD',
  '2D',
  '3D',
  '4D',
  '5D',
  '6D',
  '7D',
  '8D',
  '9D',
  '10D',
  'JD',
  'QD',
  'KD',
  'BJ',
]

const FormatCard = (card: string) => `${SUIT[card.slice(-1)]}${card.slice(0, -1).replace('L', '小').replace('B', '大')}`

const CARD_TYPES = [
  'high',
  'pairs',
  'trips',
  'bomb',
  'straight',
  'full_house',
  'three_pairs',
  'two_trips',
  'pass',
  'straight_flush',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '16',
  '17',
]

export { PAYMENT_TYPE, DIRECTION_RANK, SUIT_COLOR, SUIT_COLORS, RANK_ORDER_OPTIONS, SUIT_ORDER, LEVEL_ORDER, RANK_ORDER, SUITS, POINTS, CARD_EXAMPLE, FormatCard, CARD_TYPES, SUIT }
